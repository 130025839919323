<template>
  <div class="main-content">
    <!-- 头 -->
    <div class="top" :style="{backgroundColor:isSkin}">
       <div class="thumb-title">个人中心</div>
    </div>
    <!-- 内容 -->
    <div class="contetn"> 
      <div class="head">
        <div class="head-title"  v-html="'头&emsp;&emsp;像：'"></div>
        <div>
          <el-upload
              class="avatar-uploader"
              :action="actionURL"
              :show-file-list="false"
              :on-success="successMethod">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
            </el-upload>
        </div>
      </div>
      <div class="nickname">
        <div class="nickname-title" v-html="'昵&emsp;&emsp;称：'"></div>
        <div>
          <input class="nickname-input" v-model="nickname" placeholder="请输入昵称"/>
        </div>
      </div>
      <div class="contact">
        <div class="contact-title">联系方式：</div>
        <div>
          <input class="contact-input" v-model="contact" placeholder="请输入联系方式"/>
        </div>
      </div>
      <div class="save" :style="{backgroundColor:isSkin}" @click.stop="contactMethod()">保存</div>
    </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
export default {
  data() {
    return {
      actionURL:'http://39.105.118.118:80/app/upload/upload?token='+sessionStorage.getItem('token'),
      nickname:'',
      contact:'',
      imageUrl:require('../../../assets/users/headImg.png'),
      ID:''
    }
  },
  computed:{
    isSkin(){
		  return this.$store.state.isSkin
    },
  },
  created() {
    this.getUserInfo()
  },
  methods:{
    /**获取用户信息**/
    getUserInfo(){
      let that = this
      that.$api.getUserInfo({
        file:'',
      }).then(res=>{
        if (res.data.code == 0){
          that.nickname = res.data.data.nickname
          that.contact = res.data.data.mobile
          if (res.data.data.headImgUrl == '') {
            that.imageUrl = require('../../../assets/users/headImg.png')
          }else{
            that.imageUrl = res.data.data.headImgUrl
          }
          that.ID = res.data.data.id
          sessionStorage.setItem('userNickName',res.data.data.nickname)
          sessionStorage.setItem('userHeadImg',res.data.data.headImgUrl)
          sessionStorage.setItem('bindWechat',res.data.data.bindWechat)
          this.$store.commit('changNickname',res.data.data.nickname)
          this.$store.commit('changHeadImgUrl',res.data.data.headImgUrl)
          this.$store.commit('changTeachers',res.data.data.identity)
        }else {
          that.nickname = ''
          that.imageUrl = require('../../../assets/users/headImg.png')
          that.contact = ''
          that.ID = ''
        }
      })
    },
    successMethod(res, file, fileList){
      this.imageUrl = res.data
    },
    /**保存按钮**/
    contactMethod(){
      var that = this
      that.$api.getUpdateUser({
        headImgUrl:that.imageUrl,
        nickname:that.nickname,
        tel:that.contact,
        id:that.ID
      }).then(res=>{
        if (res.data.code == 0) {
          Message({
            showClose: true,
            message: '修改成功！',
            type: 'success'
          })
          this.getUserInfo()
        } else {
          Message({
            showClose: true,
            message: '修改失败！',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.main-content{
  width: 100%;
  .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      line-height: 55px;
      .thumb-title{
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 12px;
      }
    }
  .contetn{
    width: 100%;
    padding-bottom: 50px;
    .head{
      display: flex;
      justify-items: center;
      align-items: center;
      margin-top: 50px;
      margin-left: 90px;
      .head-title{
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #333333;
        padding-right: 10px;
      }
    }
    .nickname{
      display: flex;
      justify-items: center;
      align-items: center;
      margin-top: 30px;
      margin-left: 90px;
      .nickname-title{
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #333333;
        padding-right: 10px;
      }
      .nickname-input{
        width: 387px;
        height: 40px;
        background: #FCFCFC;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        padding-left: 10px;
        font-size: 15px;
        line-height: 40px;  
        color: #666666;
        outline: none;
      }
    }
    .contact{
      display: flex;
      justify-items: center;
      align-items: center;
      margin-top: 30px;
      margin-left: 90px;
      .contact-title{
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #333333;
        padding-right: 10px;
      }
      .contact-input{
        width: 387px;
        height: 40px;
        background: #FCFCFC;
        border: 1px solid #DDDDDD;
        box-sizing: border-box;
        border-radius: 4px;
        padding-left: 10px;
        font-size: 15px;
        line-height: 40px;  
        color: #666666;
        outline: none;
      }
    }
    .save{
      width: 387px;
      height: 42px;
      font-size: 16px;
      line-height: 42px;
      text-align: center; 
      color: #FFFFFF;
      margin-left: 170px;
      margin-top: 60px;
      border-radius: 4px;
      cursor: pointer;
    }
  }  
}
.avatar-uploader{
  width: 70px;
  height: 70px;
  border-radius: 70px;
  cursor: pointer;
  background-color: #8aadff;
  overflow: hidden;
  .avatar{
    width: 70px;
    height: 70px;
  }
}
</style>